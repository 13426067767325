<template>
  <div class="all-height all-width">
    <div class="d-flex mb-2 justify-center">
      <lb-filter :filterheaders="filterOptionsData" :loading="filterLoading" :generateFileOption="true" :isFileGenarating = "isFileGenarating" @loadFilterData="getFilterData" @genarateFile="generateFile"  />
    </div>
    <div class="d-flex mb-2 position-absolute">
      <div class="d-flex align-center" v-if="pendingCountDisplay">
        <span class="display-1" v-if="dataStatusCount.open !== -1">{{ dataStatusCount.open }}</span>
        <div v-else>
          <div class="loading-spinner"></div>
        </div>
        <span class="ml-2 line-1">Pending<br />Requests</span>
        
      </div>
      <div class="d-flex align-center ml-4" v-if="approvedCountDisplay">
        <span class="display-1" v-if="dataStatusCount.approved !== -1">{{ dataStatusCount.approved }}</span>
        <div v-else>
          <div class="loading-spinner"></div>
        </div>
        <span class="ml-2 line-1">Approved<br />Requests</span>
      </div>
      <div class="d-flex align-center ml-4" color="error" v-if="rejectedCountDisplay">
        <span class="display-1" v-if="dataStatusCount.rejected !== -1">{{ dataStatusCount.rejected }}</span>
        <div v-else>
          <div class="loading-spinner"></div>
        </div>
        <span class="ml-2 line-1">Rejected<br />Requests</span>
      </div>
    </div>
    <div v-if="formatteddata.length > 0" class="">
      <lb-datatable_pagination :headers="headers" :items="formatteddata" :filterheaders="filterOptionsData"
        :enableslot="['submitdate', 'submittername', 'typetext','approvedby','approvedon', 'action', 'stage']"
        :loading="(loading && filterLoading)" :sortByDate=true @loadNextPage="loadNextPage" :page="page" :metaData=meta
        @loadFilterData="getRefreshData">
        <template v-slot:typetext="{ item }">
          <v-chip label small :color="colors[item.type]">{{item.typetext}}</v-chip>
        </template>
        <template v-slot:submitdate="{ item }">
          <span>{{$nova.formatDateTime(item.submitdate)}}</span>
        </template>
        <template v-slot:submittername="{ item }">
          <span>{{item.submitter_detail?.name}}</span>
          <br />
          <span>{{item.submitter_detail?.email}}</span>
        </template>
        <template v-slot:approvedby="{ item }">
          <span>{{ item.approver_detail?.name || ""}}</span>
          <br />
          <span>{{ item.approver_detail?.email || "" }}</span>
        </template>
        <template v-slot:approvedon="{ item }">
          <span>{{ $nova.formatDateTime(item.approvedate) || "" }}</span>
        </template>
        <template v-slot:stage="{ item }">
          <v-chip label small v-if="item.stage === 'Open'" color="info">Open</v-chip>
          <v-chip label small v-else-if="item.stage === 'Approved'" color="success">Approved</v-chip>
          <v-chip label small v-else-if="item.stage === 'Rejected'" color="error">Rejected</v-chip>
          <v-chip label small v-else-if="item.stage === 'inProgress'" color="warning">In Progress</v-chip>
          <v-chip label small v-else-if="item.stage === 'Retry'" color="error">Failed</v-chip>
          <v-chip label small v-else>Unknown</v-chip>
        </template>
        <template v-slot:action="{ item }">
          <v-tooltip bottom content-class="tooltip-bottom">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on" color="" @click="getDetails(item)">
                <v-icon>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>
          <!-- <v-tooltip bottom content-class="tooltip-bottom" v-if="item.type === 'accesschange' && item.stage === 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on" color="success" @click="approveItem(item)">
                <v-icon>mdi-check</v-icon>
              </v-btn>
            </template>
            <span>Approve</span>
          </v-tooltip>
          <v-tooltip bottom content-class="tooltip-bottom" v-if="item.type === 'accesschange' && item.stage === 0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon small v-bind="attrs" v-on="on" color="error" @click="rejectItem(item)">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Reject</span>
          </v-tooltip> -->
        </template>
      </lb-datatable_pagination>
    </div>
    <div v-else-if='loading' class="title grey--text pa-5 text-center">
      Please wait as we load your data
    </div>
    <div v-else class="title grey--text pa-5 text-center">
      No Data Found
    </div>

    <lb-dialog v-model="viewdialog" :heading="viewdata.typetext" width="1000" :loading="viewloading">
      <template v-slot:body>
        <div v-if="viewdata._id">
          <div class="d-flex align-center">
            <!-- RU Level Materiality File Upload -->
            <div class="d-flex"
              v-if="viewdata.type == 'materialityrulevel' || viewdata.type == 'materialitygllevel' || viewdata.type == 'bulkmaterialityrulevel' || viewdata.type == 'filematerialityrulevel' || viewdata.type == 'bulkmaterialitygllevel' || viewdata.type == 'filematerialitygllevel'">
              <div class="align-center mr-2 px-3 bg-grey radius-small justify-center d-flex flex-column">
                <span class="blue--text font-weight-bold">
                  {{ viewdata.type == 'materialityrulevel' || viewdata.type == 'materialitygllevel' || viewdata.type == 'bulkmaterialityrulevel' || viewdata.type == 'filematerialityrulevel' ||
                  viewdata.type == 'bulkmaterialitygllevel' || viewdata.type == 'filematerialitygllevel' ? 'Total Cycle(s)'
                  :'' }}
                </span>
                <span>{{total_cycle ? total_cycle.length :  '-' }}</span>
              </div>
              <div class="align-center mx-2 px-3 bg-grey radius-small justify-center d-flex flex-column">
                <span class="success--text font-weight-bold">
                  {{ viewdata.type == 'materialityrulevel' || viewdata.type == 'materialitygllevel' || viewdata.type == 'bulkmaterialityrulevel' || viewdata.type == 'filematerialityrulevel' ||
                  viewdata.type =='bulkmaterialitygllevel' || viewdata.type == 'filematerialitygllevel' ?
                  'Processed Cycle(s)' :'' }}
                </span>
                <span>{{proccesed_cycle ? proccesed_cycle.length : '-' }}</span>
              </div>
              <div v-if="viewdata.stage >= 0 && viewdata.stage <= 2"
                class="align-center ml-2 px-3 bg-grey radius-small justify-center d-flex flex-column">
                <span class="orange--text font-weight-bold">
                  {{ viewdata.type == 'materialityrulevel' || viewdata.type == 'materialitygllevel' || viewdata.type == 'bulkmaterialityrulevel' || viewdata.type == 'filematerialityrulevel' ||
                  viewdata.type =='bulkmaterialitygllevel' || viewdata.type == 'filematerialitygllevel' ? 'Pending Cycle(s)'
                  :'' }}
                </span>
                <span>{{total_cycle && proccesed_cycle ? total_cycle.length - proccesed_cycle.length : '-' }}</span>
              </div>
              <div v-else-if="viewdata.stage == -1"
                class="align-center ml-2 px-3 bg-grey radius-small justify-center d-flex flex-column">
                <span class="error--text font-weight-bold">
                  {{ viewdata.type == 'materialityrulevel' || viewdata.type == 'materialitygllevel' || viewdata.type == 'bulkmaterialityrulevel' || viewdata.type == 'filematerialityrulevel' ||
                  viewdata.type =='bulkmaterialitygllevel' || viewdata.type == 'filematerialitygllevel' ?
                  'Rejected Cycle(s)':'' }}
                </span>
                <span>{{total_cycle && proccesed_cycle ? total_cycle.length - proccesed_cycle.length : '-' }}</span>
              </div>
              <div v-else-if="viewdata.stage == 3"
                class="align-center ml-2 px-3 bg-grey radius-small justify-center d-flex flex-column">
                <span class="error--text font-weight-bold">
                  {{ viewdata.type == 'materialityrulevel' || viewdata.type == 'materialitygllevel' || viewdata.type == 'bulkmaterialityrulevel' || viewdata.type == 'filematerialityrulevel' ||
                  viewdata.type == 'bulkmaterialitygllevel' || viewdata.type == 'filematerialitygllevel' ? 'Failed Cycle(s)'
                  :'' }}
                </span>
                <span>{{total_cycle && proccesed_cycle ? total_cycle.length - proccesed_cycle.length : '-'}}</span>
              </div>
            </div>
            <v-spacer></v-spacer>
            <v-btn icon @click="getDetails(viewdata)"> <v-icon>mdi-refresh</v-icon> </v-btn>
            <v-chip label small v-if="viewdata.stage === 'Open' || viewdata.stage === 0" color="info">Open</v-chip>
            <v-chip label small v-else-if="viewdata.stage === 'Approved' || viewdata.stage === 1"
              color="success">Approved</v-chip>
            <v-chip label small v-else-if="viewdata.stage === 'Rejected' || viewdata.stage === -1"
              color="error">Rejected</v-chip>
            <v-chip label small v-else-if="viewdata.stage === 'inProgress' || viewdata.stage === 2" color="warning">In
              Progress</v-chip>
            <v-chip label small v-else-if="viewdata.stage === 'Retry' || viewdata.stage === 3"
              color="error">Failed</v-chip>
            <div class="ml-4"
              v-if="['bulkmaterialityrulevel','filematerialityrulevel','bulkmaterialitygllevel','filematerialitygllevel','materialityrulevel','materialitygllevel'].indexOf(viewdata.type) > -1">
              <v-icon @click="viewinfo=true">mdi mdi-information-outline</v-icon>
            </div>
          </div>

          <div class="mt-2" v-if="viewdata.data[0]?.from">Applicable from: {{$nova.formatDate(viewdata.data[0]?.from,
            "MMM YYYY")}}</div>
          <div class="" v-if="viewdata.data[0]?.cycle">Cycle: {{viewdata.data[0]?.cycle}}</div>
          <div class=""
            v-if="['materialityrulevel','materialitygllevel'].indexOf(viewdata.type) > -1 && viewdata.network_details && viewdata.network_details[0]?.name ">
            Network: {{viewdata.network_details[0]?.name}}</div>
          <div class=""
            v-if=" ['materialityrulevel','materialitygllevel'].indexOf(viewdata.type) > -1 && viewdata.region_details &&viewdata.region_details[0]?.name ">
            Region: {{viewdata.region_details[0]?.name}}</div>
          <div class=""
            v-if=" ['materialityrulevel','materialitygllevel'].indexOf(viewdata.type) > -1 && viewdata.country_details && viewdata.country_details[0]?.name ">
            Country: {{viewdata.country_details[0]?.name}}</div>
          <div v-if="viewdata.type === 'accesschange' || viewdata.type === 'bulkaccesschange'">
            <div class="mt-2">New users will be added/removed inline with the information provided below.</div>
            <div class="my-4">
              <div class="font-weight-bold">Selected Reporting unit(s)</div>
              <v-chip label small class="mr-1 mb-1 pl-0" outlined :key="k"
                v-for="(v,k) in viewdata.reportingunit_detail">
                <v-chip label small class="mr-2">{{v.code}}</v-chip>
                {{v.name}}
              </v-chip>
            </div>
            <div v-if="viewdata.data[0]?.adduser" class="mt-4">
              <div class="border-left-success pl-2">Add Users</div>
              <div class="ml-4 mt-1">
                <div v-if="(viewdata.data[0].adduser?.submitter || []).length > 0" class="mb-1">
                  <div class="font-weight-bold">Local Preparer(s)</div>
                  <v-chip label small class="mr-1 mb-1" outlined :key="k"
                    v-for="(v,k) in (viewdata.data[0].adduser?.submitter || [])">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{viewdata.__userlist[v]?.email || "Unknown"}}
                  </v-chip>
                </div>
                <div v-if="(viewdata.data[0].adduser?.approver || []).length > 0" class="mb-1">
                  <div class="font-weight-bold">Local Approver(s)</div>
                  <v-chip label small class="mr-1 mb-1" outlined :key="k"
                    v-for="(v,k) in (viewdata.data[0].adduser?.approver || [])">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{viewdata.__userlist[v]?.email || "Unknown"}}
                  </v-chip>
                </div>
                <div v-if="(viewdata.data[0].adduser?.manager || []).length > 0" class="mb-1">
                  <div class="font-weight-bold">Manager(s)</div>
                  <v-chip label small class="mr-1 mb-1" outlined :key="k"
                    v-for="(v,k) in (viewdata.data[0].adduser?.manager || [])">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{viewdata.__userlist[v]?.email || "Unknown"}}
                  </v-chip>
                </div>
                <div v-if="(viewdata.data[0].adduser?.regional_approver || []).length > 0" class="mb-1">
                  <div class="font-weight-bold">Regional Approver(s)</div>
                  <v-chip label small class="mr-1 mb-1" outlined :key="k"
                    v-for="(v,k) in (viewdata.data[0].adduser?.regional_approver || [])">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{viewdata.__userlist[v]?.email || "Unknown"}}
                  </v-chip>
                </div>
              </div>
            </div>
            <div v-if="viewdata.data[0]?.removeuser" class="mt-4">
              <div class="border-left-error pl-2">Remove Users</div>
              <div class="ml-4 mt-1">
                <div v-if="(viewdata.data[0].removeuser?.submitter || []).length > 0" class="mb-1">
                  <div class="font-weight-bold">Local Preparer(s)</div>
                  <v-chip label small class="mr-1 mb-1" outlined :key="k"
                    v-for="(v,k) in (viewdata.data[0].removeuser?.submitter || [])">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{viewdata.__userlist[v]?.email || "Unknown"}}
                  </v-chip>
                </div>
                <div v-if="(viewdata.data[0].removeuser?.approver || []).length > 0" class="mb-1">
                  <div class="font-weight-bold">Local Approver(s)</div>
                  <v-chip label small class="mr-1 mb-1" outlined :key="k"
                    v-for="(v,k) in (viewdata.data[0].removeuser?.approver || [])">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{viewdata.__userlist[v]?.email || "Unknown"}}
                  </v-chip>
                </div>
                <div v-if="(viewdata.data[0].removeuser?.manager || []).length > 0" class="mb-1">
                  <div class="font-weight-bold">Manager(s)</div>
                  <v-chip label small class="mr-1 mb-1" outlined :key="k"
                    v-for="(v,k) in (viewdata.data[0].removeuser?.manager || [])">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{viewdata.__userlist[v]?.email || "Unknown"}}
                  </v-chip>
                </div>
                <div v-if="(viewdata.data[0].removeuser?.regional_approver || []).length > 0" class="mb-1">
                  <div class="font-weight-bold">Regional Approver(s)</div>
                  <v-chip label small class="mr-1 mb-1" outlined :key="k"
                    v-for="(v,k) in (viewdata.data[0].removeuser?.regional_approver || [])">
                    <v-icon class="mr-1">mdi-account</v-icon>
                    {{viewdata.__userlist[v]?.email || "Unknown"}}
                  </v-chip>
                </div>
              </div>
            </div>
          </div>
          <div v-else-if="viewdata.type === 'fileaccesschange'">
            <div class="mt-2">All existing user of the below reporting units will be replaced by the user list
              provided
              under each reportin unit detail.</div>
            <div class="my-4" v-for="(v,k) in viewdata.data" :key="k">
              <v-chip label small class="mr-1 mb-1 pl-0" outlined>
                <v-chip label small class="mr-2">{{viewdata.__reportingunit_detail[v.rucode].code}}</v-chip>
                {{viewdata.__reportingunit_detail[v.rucode].name}}
              </v-chip>
              <div class="mt-2">
                <div class="mt-1 ml-4">
                  <div v-if="(v?.submitter || []).length > 0" class="mb-1">
                    <div class="font-weight-bold">Local Preparer(s)</div>
                    <v-chip label small class="mr-1 mb-1" outlined :key="vk" v-for="(vv,vk) in (v?.submitter || [])">
                      <v-icon class="mr-1">mdi-account</v-icon>
                      {{viewdata.__userlist[vv]?.email || "Unknown"}}
                    </v-chip>
                  </div>
                  <div v-if="(v?.approver || []).length > 0" class="mb-1">
                    <div class="font-weight-bold">Local Approver(s)</div>
                    <v-chip label small class="mr-1 mb-1" outlined :key="vk" v-for="(vv,vk) in (v?.approver || [])">
                      <v-icon class="mr-1">mdi-account</v-icon>
                      {{viewdata.__userlist[vv]?.email || "Unknown"}}
                    </v-chip>
                  </div>
                  <div v-if="(v?.manager || []).length > 0" class="mb-1">
                    <div class="font-weight-bold">Manager(s)</div>
                    <v-chip label small class="mr-1 mb-1" outlined :key="vk" v-for="(vv,vk) in (v?.manager || [])">
                      <v-icon class="mr-1">mdi-account</v-icon>
                      {{viewdata.__userlist[vv]?.email || "Unknown"}}
                    </v-chip>
                  </div>
                  <div v-if="(v?.regional_approver || []).length > 0" class="mb-1">
                    <div class="font-weight-bold">Regional Approver(s)</div>
                    <v-chip label small class="mr-1 mb-1" outlined :key="vk"
                      v-for="(vv,vk) in (v?.regional_approver || [])">
                      <v-icon class="mr-1">mdi-account</v-icon>
                      {{viewdata.__userlist[vv]?.email || "Unknown"}}
                    </v-chip>
                  </div>
                </div>
              </div>
              <v-divider class="my-2" />
            </div>
          </div>
          <!-- Bulk RU Level Materiality -->
          <div v-else-if="viewdata.type === 'materialityrulevel' || viewdata.type === 'bulkmaterialityrulevel'">
            <div class="d-flex flex-column">
              <div v-if="(viewdata.data[0]?.periods || []).length" class="selected-months mb-2">
                <h3>Applicable Periods:</h3>
                <ul>
                <li v-for="(month, index) in viewdata.data[0]?.periods" :key="index" class=' month-item'>
                    {{ month }}
                </li>
                </ul>
            </div>
              <div class="font-weight-bold">Selected Reporting unit(s)</div>
              <div label small
                class="mr-1 border-on pr-2 radius-small  d-flex align-center justify-space-between all-width mb-2 pl-0"
                outlined :key="k" v-for="(v,k) in viewdata.reportingunit_detail">
                <v-chip label small class="mr-2">{{v.code}}</v-chip>
                {{v.name}}
                <v-spacer></v-spacer>
                <v-icon
                  v-if="proccesed_cycle?.includes(v._id) && (viewdata.stage === 'inProgress' || viewdata.stage === 2 || viewdata.stage === 'retry' || viewdata.stage === 3 || viewdata.stage === 'Approved' || viewdata.stage === 1 ) "
                  color="success">mdi-check-circle</v-icon>
              </div>
            </div>
            <div class="mt-2">
              <lb-datatable :headers="ruheaders" :items="gldata['ru'] || []"
                :enableslot="['period','datatypes', 'control_thresholds_amount', 'control_thresholds_percent', 'floor_control_thresholds_amount', 'management_thresholds_amount', 'management_thresholds_percent', 'floor_management_thresholds_amount', 'control_thresholds_amount_regional', 'control_thresholds_percent_regional', 'floor_control_thresholds_amount_regional','management_thresholds_amount_regional','management_thresholds_percent_regional','floor_management_thresholds_amount_regional']">
                <template v-slot:period="{ item }">
                  <div class="text-center" v-if="item.period">
                    {{ item?.period || 'All' }}</div>
                  <div class="text-center" v-else>-</div>
                </template>
                <template v-slot:datatypes="">
                  <div class='ellipsis-on text-left'>
                    vs Proforma
                  </div>
                  <div class='ellipsis-on text-left'>
                    vs Forecast
                  </div>
                </template>
                <template v-slot:control_thresholds_amount="{ item }">
                  <div class="text-right" v-if="item.control_thresholds_amount_proforma || item.control_thresholds_amount_proforma === 0">
                    {{ $nova.formatNumber(item.control_thresholds_amount_proforma) || 0 }}</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.control_thresholds_amount_forecast || item.control_thresholds_amount_forecast === 0">
                    {{ $nova.formatNumber(item.control_thresholds_amount_forecast) || 0 }}</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <template v-slot:control_thresholds_percent="{ item }">
                  <div class="text-right" v-if="item.control_thresholds_percent_proforma || item.control_thresholds_percent_proforma === 0">
                    {{ item.control_thresholds_percent_proforma || 0 }}%</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.control_thresholds_percent_forecast || item.control_thresholds_percent_forecast === 0">
                    {{ item.control_thresholds_percent_forecast || 0 }}%</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <!-- <template v-slot:floor_control_thresholds_amount="{ item }">
                  <div class="text-right" v-if="item.floor_control_thresholds_amount_proforma">
                    {{ $nova.formatNumber(item.floor_control_thresholds_amount_proforma) || 0 }}</div>
                    <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.floor_control_thresholds_amount_forecast">
                    {{ $nova.formatNumber(item.floor_control_thresholds_amount_forecast) || 0 }}</div>
                    <div class="text-right" v-else>-</div>
                </template> -->
                <template v-slot:management_thresholds_amount="{ item }">
                  <div class="text-right" v-if="item.management_thresholds_amount_proforma || item.management_thresholds_amount_proforma === 0">
                    {{ $nova.formatNumber(item.management_thresholds_amount_proforma) }}</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.management_thresholds_amount_forecast || item.management_thresholds_amount_forecast === 0">
                    {{ $nova.formatNumber(item.management_thresholds_amount_forecast) }}</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <template v-slot:management_thresholds_percent="{ item }">
                  <div class="text-right" v-if="item.management_thresholds_percent_proforma || item.management_thresholds_percent_proforma === 0">
                    {{ item.management_thresholds_percent_proforma || 0 }}%</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.management_thresholds_percent_forecast || item.management_thresholds_percent_forecast === 0">
                    {{ item.management_thresholds_percent_forecast || 0 }}%</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <!-- <template v-slot:floor_management_thresholds_amount="{ item }">
                  <div class="text-right" v-if="item.floor_management_thresholds_amount_proforma">
                    {{ $nova.formatNumber(item.floor_management_thresholds_amount_proforma) }}</div>
                    <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.floor_management_thresholds_amount_forecast">
                    {{ $nova.formatNumber(item.floor_management_thresholds_amount_forecast) }}</div>
                    <div class="text-right" v-else>-</div>
                </template> -->
                <template v-slot:control_thresholds_amount_regional="{ item }">
                  <div class="text-right" v-if="item.control_thresholds_amount_regional_proforma || item.control_thresholds_amount_regional_proforma === 0">
                    {{ $nova.formatNumber(item.control_thresholds_amount_regional_proforma) || 0 }}</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.control_thresholds_amount_regional_forecast || item.control_thresholds_amount_regional_forecast === 0">
                    {{ $nova.formatNumber(item.control_thresholds_amount_regional_forecast) || 0}}</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <template v-slot:control_thresholds_percent_regional="{ item }">
                  <div class="text-right" v-if="item.control_thresholds_percent_regional_proforma || item.control_thresholds_percent_regional_proforma === 0">
                    {{ item.control_thresholds_percent_regional_proforma }}%</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.control_thresholds_percent_regional_forecast || item.control_thresholds_percent_regional_forecast === 0">
                    {{ item.control_thresholds_percent_regional_forecast }}%</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <!-- <template v-slot:floor_control_thresholds_amount_regional="{ item }">
                  <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_proforma">
                    {{ item.floor_control_thresholds_amount_regional_proforma }}%</div>
                    <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_forecast">
                    {{ item.floor_control_thresholds_amount_regional_forecast }}%</div>
                    <div class="text-right" v-else>-</div>
                </template> -->
                <template v-slot:management_thresholds_amount_regional="{ item }">
                  <div class="text-right" v-if="item.management_thresholds_amount_regional_proforma || item.management_thresholds_amount_regional_proforma === 0">
                    {{ item.management_thresholds_amount_regional_proforma }}</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.management_thresholds_amount_regional_forecast || item.management_thresholds_amount_regional_forecast === 0">
                    {{ $nova.formatNumber(item.management_thresholds_amount_regional_forecast) }}</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <template v-slot:management_thresholds_percent_regional="{ item }">
                  <div class="text-right" v-if="item.management_thresholds_percent_regional_proforma || item.management_thresholds_percent_regional_proforma === 0">
                    {{ $nova.formatNumber(item.management_thresholds_percent_regional_proforma) }}%</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.management_thresholds_percent_regional_forecast || item.management_thresholds_percent_regional_forecast === 0">
                    {{ $nova.formatNumber(item.management_thresholds_percent_regional_forecast) }}%</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <!-- <template v-slot:floor_management_thresholds_amount_regional="{ item }">
                  <div class="text-right" v-if="item.floor_management_thresholds_amount_regional_proforma">
                    {{ $nova.formatNumber(item.floor_management_thresholds_amount_regional_proforma) }}</div>
                    <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.floor_management_thresholds_amount_regional_forecast">
                    {{ $nova.formatNumber(item.floor_management_thresholds_amount_regional_forecast) }}</div>
                    <div class="text-right" v-else>-</div>
                </template> -->
              </lb-datatable>
            </div>
          </div>
          <div v-else-if="viewdata.type === 'filematerialityrulevel'">
            <div class="my-4" v-for="(v,k) in Object.fromEntries(Object.entries(gldata).slice(materialityDetailsPage*25, (materialityDetailsPage+1)*25))" :key="k">
              <div class="d-flex align-center justify-space-between">
                <v-chip label small class="mr-1 mb-1 pl-0" outlined>
                  <v-chip label small class="mr-2">{{viewdata.__reportingunit_detail[k].code}}</v-chip>
                  {{viewdata.__reportingunit_detail[k].name}}
                </v-chip>
                <v-icon
                  v-if="proccesed_Ru?.includes(viewdata.__reportingunit_detail[k]._id) && (viewdata.stage === 'inProgress' || viewdata.stage === 2 || viewdata.stage === 'retry' || viewdata.stage === 3 || viewdata.stage === 'Approved' || viewdata.stage === 1 ) "
                  color="success">mdi-check-circle</v-icon>
              </div>
              <template v-for="(vv, kk) in ['actual', 'forecast']">
                <div v-if="(gldata[k] || {})[vv]" :key="kk" class="ml-4">
                  <div class="font-weight-bold">{{typekeys[vv]}}</div>
                  <lb-datatable :headers="ruheaders" :items="gldata[k][vv] || []"
                    :enableslot="['datatypes', 'control_thresholds_amount', 'control_thresholds_percent', 'floor_control_thresholds_amount', 'management_thresholds_amount', 'management_thresholds_percent', 'floor_management_thresholds_amount', 'control_thresholds_amount_regional', 'control_thresholds_percent_regional', 'floor_control_thresholds_amount_regional', 'management_thresholds_amount_regional', 'management_thresholds_percent_regional', 'floor_management_thresholds_regional']"
                    :perpage="5">
                    <template v-slot:datatypes=''>
                      <div class='ellipsis-on text-left'>
                        vs Proforma
                      </div>
                      <div class='ellipsis-on text-left'>
                        vs Forecast
                      </div>
                    </template>
                    <template v-slot:control_thresholds_amount="{ item }">
                      <div class="text-right" v-if="item.control_thresholds_amount_proforma || item.control_thresholds_amount_proforma === 0">
                        {{ $nova.formatNumber(item.control_thresholds_amount_proforma) || 0 }}</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.control_thresholds_amount_forecast || item.control_thresholds_amount_forecast === 0">
                        {{ $nova.formatNumber(item.control_thresholds_amount_forecast) || 0 }}</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <template v-slot:control_thresholds_percent="{ item }">
                      <div class="text-right" v-if="item.control_thresholds_percent_proforma || item.control_thresholds_percent_proforma === 0">
                        {{ item.control_thresholds_percent_proforma || 0 }}%</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.control_thresholds_percent_forecast || item.control_thresholds_percent_forecast === 0">
                        {{ item.control_thresholds_percent_forecast || 0 }}%</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <!-- <template v-slot:floor_control_thresholds_amount="{ item }">
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_proforma">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_proforma) || 0 }}</div>
                        <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_forecast">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_forecast) || 0 }}</div>
                        <div class="text-right" v-else>-</div>
                    </template> -->
                    <template v-slot:management_thresholds_amount="{ item }">
                      <div class="text-right" v-if="item.management_thresholds_amount_proforma || item.management_thresholds_amount_proforma === 0">
                        {{ $nova.formatNumber(item.management_thresholds_amount_proforma) || 0 }}</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.management_thresholds_amount_forecast || item.management_thresholds_amount_forecast === 0">
                        {{ $nova.formatNumber(item.management_thresholds_amount_forecast) || 0 }}</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <template v-slot:management_thresholds_percent="{ item }">
                      <div class="text-right" v-if="item.management_thresholds_percent_proforma || item.management_thresholds_percent_proforma === 0">
                        {{ item.management_thresholds_percent_proforma || 0 }}%</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.management_thresholds_percent_forecast || item.management_thresholds_percent_forecast === 0">
                        {{ item.management_thresholds_percent_forecast || 0 }}%</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <!-- <template v-slot:floor_management_thresholds_amount="{ item }">
                      <div class="text-right" v-if="item.floor_management_thresholds_amount_proforma">
                        {{ $nova.formatNumber(item.floor_management_thresholds_amount_proforma) }}</div>
                        <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.floor_management_threshold_amount_forecast">
                        {{ $nova.formatNumber(item.floor_management_threshold_amount_forecast) }}</div>
                        <div class="text-right" v-else>-</div>
                    </template> -->
                    <template v-slot:control_thresholds_amount_regional="{ item }">
                      <div class="text-right" v-if="item.control_thresholds_amount_regional_proforma || item.control_thresholds_amount_regional_proforma === 0">
                        {{ $nova.formatNumber(item.control_thresholds_amount_regional_proforma) || item.control_thresholds_amount_proforma === 0}}</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.control_thresholds_amount_regional_forecast || item.control_thresholds_amount_regional_forecast === 0">
                        {{ $nova.formatNumber(item.control_thresholds_amount_regional_forecast) }}</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <template v-slot:control_thresholds_percent_regional="{ item }">
                      <div class="text-right" v-if="item.control_thresholds_percent_regional_proforma || item.control_thresholds_percent_regional_proforma === 0">
                        {{ item.control_thresholds_percent_regional_proforma }}%</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.control_thresholds_percent_regional_forecast || item.control_thresholds_percent_regional_forecast === 0">
                        {{ item.control_thresholds_percent_regional_forecast }}%</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <!-- <template v-slot:floor_control_thresholds_amount_regional="{ item }">
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_proforma">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_regional_proforma) }}</div>
                        <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_proforma">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_regional_proforma) }}</div>
                        <div class="text-right" v-else>-</div>
                    </template> -->
                    <template v-slot:management_thresholds_amount_regional="{ item }">
                      <div class="text-right" v-if="item.management_thresholds_amount_regional_proforma || item.management_thresholds_amount_regional_proforma === 0">
                        {{ item.management_thresholds_amount_regional_proforma }}</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.management_thresholds_amount_regional_forecast || item.management_thresholds_amount_regional_forecast === 0">
                        {{ $nova.formatNumber(item.management_thresholds_amount_regional_forecast) }}</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <template v-slot:management_thresholds_percent_regional="{ item }">
                      <div class="text-right" v-if="item.management_thresholds_percent_regional_proforma || item.management_thresholds_percent_regional_proforma === 0">
                        {{ $nova.formatNumber(item.management_thresholds_percent_regional_proforma) }}%</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.management_thresholds_percent_regional_forecast || item.management_thresholds_percent_regional_forecast === 0">
                        {{ $nova.formatNumber(item.management_thresholds_percent_regional_forecast) }}%</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <!-- <template v-slot:floor_management_thresholds_regional="{ item }">
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_proforma">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_regional_proforma) }}</div>
                        <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_forecast">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_regional_forecast) }}</div>
                        <div class="text-right" v-else>-</div>
                    </template> -->
                  </lb-datatable>
                </div>
              </template>
              <v-divider class="my-2" />
            </div>
            <!-- <div v-if="Object.keys(gldata).length > 1000" class="grey--text py-4 text-center body-1"> {{Object.keys(gldata).length-1000}} more</div> -->
          </div>
          <!-- Bulk GL Level Materiality -->
          <div v-else-if="viewdata.type === 'materialitygllevel' || viewdata.type === 'bulkmaterialitygllevel'">
            <div class="my-4 d-flex flex-column">
              <div class="font-weight-bold">Selected Reporting unit(s)</div>
              <div label small
                class="mr-1 border-on pr-2 radius-small  d-flex align-center justify-space-between all-width mb-2 pl-0"
                outlined :key="k" v-for="(v,k) in viewdata.reportingunit_detail">
                <v-chip label small class="mr-2">{{v.code}}</v-chip>
                {{v.name}}
                <v-spacer></v-spacer>
                <v-icon
                  v-if="proccesed_Ru?.includes(v._id) && (viewdata.stage === 'inProgress' || viewdata.stage === 2 || viewdata.stage === 'retry' || viewdata.stage === 3 || viewdata.stage === 'Approved' || viewdata.stage === 1 )  "
                  color="success">mdi-check-circle</v-icon>
              </div>
              <div v-if="(viewdata.data[0]?.periods || []).length" class="selected-months mb-2">
                <h3>Applicable Periods:</h3>
                <ul>
                <li v-for="(month, index) in viewdata.data[0]?.periods" :key="index" class=' month-item'>
                    {{ month }}
                </li>
                </ul>
            </div>
            </div>
            <div class="mt-2">
              <lb-datatable :headers="glheaders" :items="gldata['one'] || []"
                :enableslot="['period','datatypes', 'control_thresholds_amount', 'control_thresholds_percent', 'floor_control_thresholds_amount', 'management_thresholds_amount', 'management_thresholds_percent', 'floor_management_thresholds_amount', 'control_thresholds_amount_regional', 'control_thresholds_percent_regional', 'floor_control_thresholds_amount_regional', 'management_thresholds_amount_regional', 'management_thresholds_percent_regional', 'floor_management_thresholds_amount_regional']"
                :loading=viewloading>
                <template v-slot:period="{ item }">
                  <div class="text-center" v-if="item.period">
                    {{ item?.period || 'All' }}</div>
                  <div class="text-center" v-else>-</div>
                </template>
                <template v-slot:datatypes=''>
                  <div class='ellipsis-on text-left'>
                    vs Proforma
                  </div>
                  <div class='ellipsis-on text-left'>
                    vs Forecast
                  </div>
                </template>
                <template v-slot:control_thresholds_amount="{ item }">
                  <div class="text-right" v-if="item.control_thresholds_amount_proforma || item.control_thresholds_amount_proforma === 0">
                    {{ $nova.formatNumber(item.control_thresholds_amount_proforma) || 0 }}</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.control_thresholds_amount_forecast || item.control_thresholds_amount_forecast === 0">
                    {{ $nova.formatNumber(item.control_thresholds_amount_forecast) || 0 }}</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <template v-slot:control_thresholds_percent="{ item }">
                  <div class="text-right" v-if="item.control_thresholds_percent_proforma || item.control_thresholds_percent_proforma === 0">
                    {{ item.control_thresholds_percent_proforma || 0 }}%</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.control_thresholds_percent_forecast || item.control_thresholds_percent_forecast === 0">
                    {{ item.control_thresholds_percent_forecast || 0 }}%</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <!-- <template v-slot:floor_control_thresholds_amount="{ item }">
                  <div class="text-right" v-if="item.floor_control_thresholds_amount_proforma">
                    {{ $nova.formatNumber(item.floor_control_thresholds_amount_proforma) || 0 }}</div>
                    <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.floor_control_thresholds_amount_forecast">
                    {{ $nova.formatNumber(item.floor_control_thresholds_amount_forecast) || 0 }}</div>
                    <div class="text-right" v-else>-</div>
                </template> -->
                <template v-slot:management_thresholds_amount="{ item }">
                  <div class="text-right" v-if="item.management_thresholds_amount_proforma || item.management_thresholds_amount_proforma === 0">
                    {{ $nova.formatNumber(item.management_thresholds_amount_proforma) || 0 }}</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.management_thresholds_amount_forecast || item.management_thresholds_amount_forecast === 0">
                    {{ $nova.formatNumber(item.management_thresholds_amount_forecast) || 0 }}</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <template v-slot:management_thresholds_percent="{ item }">
                  <div class="text-right" v-if="item.management_thresholds_percent_proforma || item.management_thresholds_percent_proforma === 0">
                    {{ item.management_thresholds_percent_proforma || 0 }}%</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.management_thresholds_percent_forecast || item.management_thresholds_percent_forecast === 0">
                    {{ item.management_thresholds_percent_forecast || 0 }}%</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <!-- <template v-slot:floor_management_thresholds_amount="{ item }">
                  <div class="text-right" v-if="item.floor_management_thresholds_amount_proforma">
                    {{ $nova.formatNumber(item.floor_management_thresholds_amount_proforma) }}</div>
                    <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.floor_management_thresholds_amount_forecast">
                    {{ $nova.formatNumber(item.floor_management_thresholds_amount_forecast) }}</div>
                    <div class="text-right" v-else>-</div>
                </template> -->
                <template v-slot:control_thresholds_amount_regional="{ item }">
                  <div class="text-right" v-if="item.control_thresholds_amount_regional_proforma || item.control_thresholds_amount_regional_proforma === 0">
                    {{ $nova.formatNumber(item.control_thresholds_amount_regional_proforma) }}</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.control_thresholds_amount_regional_forecast || item.control_thresholds_amount_regional_forecast === 0">
                    {{ $nova.formatNumber(item.control_thresholds_amount_regional_forecast) }}</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <template v-slot:control_thresholds_percent_regional="{ item }">
                  <div class="text-right" v-if="item.control_thresholds_percent_regional_proforma || item.control_thresholds_percent_regional_proforma === 0">
                    {{ item.control_thresholds_percent_regional_proforma }}%</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.control_thresholds_percent_regional_forecast || item.control_thresholds_percent_regional_forecast === 0">
                    {{ item.control_thresholds_percent_regional_forecast }}%</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <!-- <template v-slot:floor_control_thresholds_amount_regional="{ item }">
                  <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_proforma">
                    {{ item.floor_control_thresholds_amount_regional_proforma }}%</div>
                    <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_forecast">
                    {{ item.floor_control_thresholds_amount_regional_forecast }}%</div>
                    <div class="text-right" v-else>-</div>
                </template> -->
                <template v-slot:management_thresholds_amount_regional="{ item }">
                  <div class="text-right" v-if="item.management_thresholds_amount_regional_proforma || item.management_thresholds_amount_regional_proforma === 0">
                    {{ item.management_thresholds_amount_regional_proforma }}</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.management_thresholds_amount_regional_forecast || item.management_thresholds_amount_regional_forecast === 0">
                    {{ $nova.formatNumber(item.management_thresholds_amount_regional_forecast) }}</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <template v-slot:management_thresholds_percent_regional="{ item }">
                  <div class="text-right" v-if="item.management_thresholds_percent_regional_proforma || item.management_thresholds_percent_regional_proforma === 0">
                    {{ $nova.formatNumber(item.management_thresholds_percent_regional_proforma) }}%</div>
                  <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.management_thresholds_percent_regional_forecast || item.management_thresholds_percent_regional_forecast === 0">
                    {{ $nova.formatNumber(item.management_thresholds_percent_regional_forecast) }}%</div>
                  <div class="text-right" v-else>-</div>
                </template>
                <!-- <template v-slot:floor_management_thresholds_amount_regional="{ item }">
                  <div class="text-right" v-if="item.floor_management_thresholds_amount_regional_proforma">
                    {{ $nova.formatNumber(item.floor_management_thresholds_amount_regional_proforma) }}</div>
                    <div class="text-right" v-else>-</div>
                  <div class="text-right" v-if="item.floor_management_thresholds_amount_regional_forecast">
                    {{ $nova.formatNumber(item.floor_management_thresholds_amount_regional_forecast) }}</div>
                    <div class="text-right" v-else>-</div>
                </template> -->
              </lb-datatable>
            </div>
          </div>
          <div v-else-if="viewdata.type === 'filematerialitygllevel'">
            <div class="my-4" v-for="(v,k) in Object.fromEntries(Object.entries(gldata).slice(materialityDetailsPage*25, (materialityDetailsPage+1)*25))" :key="k">
              <div class="d-flex ">
                <v-chip label small class="mr-1 mb-1 pl-0" outlined>
                  <v-chip label small class="mr-2">{{viewdata.__reportingunit_detail[k].code}}</v-chip>
                  {{viewdata.__reportingunit_detail[k].name}}
                </v-chip>
                <v-spacer></v-spacer>
                <v-icon
                  v-if="proccesed_Ru?.includes(viewdata.__reportingunit_detail[k]._id) && (viewdata.stage === 'inProgress' || viewdata.stage === 2 || viewdata.stage === 'retry' || viewdata.stage === 3 || viewdata.stage === 'Approved' || viewdata.stage === 1 ) "
                  color="success">mdi-check-circle</v-icon>
              </div>
              <template v-for="(vv, kk) in ['actual', 'forecast']">
                <div v-if="(gldata[k] || {})[vv]" :key="kk" class="ml-4">
                  <div class="font-weight-bold">{{typekeys[vv]}}</div>
                  <lb-datatable :headers="glheaders" :items="gldata[k][vv] || []"
                    :enableslot="['datatypes', 'control_thresholds_amount', 'control_thresholds_percent', 'floor_control_thresholds_amount', 'management_thresholds_amount', 'management_thresholds_percent', 'floor_management_thresholds_amount', 'control_thresholds_amount_regional', 'control_thresholds_percent_regional', 'floor_control_thresholds_amount_regional', 'management_thresholds_amount_regional', 'management_thresholds_percent_regional', 'floor_management_thresholds_amount_regional']"
                    :loading=viewloading
                    :perpage="5">
                    <template v-slot:datatypes=''>
                      <div class='ellipsis-on text-left'>
                        vs Proforma
                      </div>
                      <div class='ellipsis-on text-left'>
                        vs Forecast
                      </div>
                    </template>
                    <template v-slot:control_thresholds_amount="{ item }">
                      <div class="text-right" v-if="item.control_thresholds_amount_proforma || item.control_thresholds_amount_proforma === 0">
                        {{ $nova.formatNumber(item.control_thresholds_amount_proforma) || 0 }}</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.control_thresholds_amount_forecast || item.control_thresholds_amount_forecast === 0">
                        {{ $nova.formatNumber(item.control_thresholds_amount_forecast) || 0 }}</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <template v-slot:control_thresholds_percent="{ item }">
                      <div class="text-right" v-if="item.control_thresholds_percent_proforma || item.control_thresholds_percent_proforma === 0">
                        {{ item.control_thresholds_percent_proforma || 0 }}%</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.control_thresholds_percent_forecast || item.control_thresholds_percent_forecast === 0">
                        {{ item.control_thresholds_percent_forecast || 0 }}%</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <!-- <template v-slot:floor_control_thresholds_amount="{ item }">
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_proforma">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_proforma) || 0 }}</div>
                        <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_forecast">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_forecast) || 0 }}</div>
                        <div class="text-right" v-else>-</div>
                    </template> -->
                    <template v-slot:management_thresholds_amount="{ item }">
                      <div class="text-right" v-if="item.management_thresholds_amount_proforma || item.management_thresholds_amount_proforma === 0">
                        {{ $nova.formatNumber(item.management_thresholds_amount_proforma) || 0 }}</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.management_thresholds_amount_forecast || item.management_thresholds_amount_forecast === 0">
                        {{ $nova.formatNumber(item.management_thresholds_amount_forecast) || 0 }}</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <template v-slot:management_thresholds_percent="{ item }">
                      <div class="text-right" v-if="item.management_thresholds_percent_proforma || item.management_thresholds_percent_proforma === 0">
                        {{ item.management_thresholds_percent_proforma || 0 }}%</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.management_thresholds_percent_forecast || item.management_thresholds_percent_forecast === 0">
                        {{ item.management_thresholds_percent_forecast || 0 }}%</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <!-- <template v-slot:floor_management_thresholds_amount="{ item }">
                      <div class="text-right" v-if="item.floor_management_thresholds_amount_proforma">
                        {{ $nova.formatNumber(item.floor_management_thresholds_amount_proforma) }}</div>
                        <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.floor_management_threshold_amount_forecast">
                        {{ $nova.formatNumber(item.floor_management_threshold_amount_forecast) }}</div>
                        <div class="text-right" v-else>-</div>
                    </template> -->
                    <template v-slot:control_thresholds_amount_regional="{ item }">
                      <div class="text-right" v-if="item.control_thresholds_amount_regional_proforma || item.control_thresholds_amount_regional_proforma === 0">
                        {{ $nova.formatNumber(item.control_thresholds_amount_regional_proforma) }}</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.control_thresholds_amount_regional_forecast || item.control_thresholds_amount_regional_forecast === 0">
                        {{ $nova.formatNumber(item.control_thresholds_amount_regional_forecast) }}</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <template v-slot:control_thresholds_percent_regional="{ item }">
                      <div class="text-right" v-if="item.control_thresholds_percent_regional_proforma || item.control_thresholds_percent_regional_proforma === 0">
                        {{ item.control_thresholds_percent_regional_proforma }}%</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.control_thresholds_percent_regional_forecast || item.control_thresholds_percent_regional_forecast === 0">
                        {{ item.control_thresholds_percent_regional_forecast }}%</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <!-- <template v-slot:floor_control_thresholds_amount_regional="{ item }">
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_proforma">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_regional_proforma) }}</div>
                        <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_proforma">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_regional_proforma) }}</div>
                        <div class="text-right" v-else>-</div>
                    </template> -->
                    <template v-slot:management_thresholds_amount_regional="{ item }">
                      <div class="text-right" v-if="item.management_thresholds_amount_regional_proforma || item.management_thresholds_amount_regional_proforma === 0">
                        {{ item.management_thresholds_amount_regional_proforma }}</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.management_thresholds_amount_regional_forecast || item.management_thresholds_amount_regional_forecast === 0">
                        {{ $nova.formatNumber(item.management_thresholds_amount_regional_forecast) }}</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <template v-slot:management_thresholds_percent_regional="{ item }">
                      <div class="text-right" v-if="item.management_thresholds_percent_regional_proforma || item.management_thresholds_percent_regional_proforma === 0">
                        {{ $nova.formatNumber(item.management_thresholds_percent_regional_proforma) }}%</div>
                      <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.management_thresholds_percent_regional_forecast || item.management_thresholds_percent_regional_forecast === 0">
                        {{ $nova.formatNumber(item.management_thresholds_percent_regional_forecast) }}%</div>
                      <div class="text-right" v-else>-</div>
                    </template>
                    <!-- <template v-slot:floor_management_thresholds_regional="{ item }">
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_proforma">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_regional_proforma) }}</div>
                        <div class="text-right" v-else>-</div>
                      <div class="text-right" v-if="item.floor_control_thresholds_amount_regional_forecast">
                        {{ $nova.formatNumber(item.floor_control_thresholds_amount_regional_forecast) }}</div>
                        <div class="text-right" v-else>-</div>
                    </template> -->
                  </lb-datatable>
                </div>
              </template>
              <v-divider class="my-2" />
            </div>
          </div>
          <div v-else-if="viewdata.type=== 'glselectionchange'">
            <div class="my-4">
            <div class="d-flex flex-column">
              <div v-if="(viewdata?.periods || []).length" class="selected-months mb-2">
                <h3>Applicable Periods:</h3>
                <ul>
                <li v-for="(month, index) in viewdata?.periods" :key="index" class=' month-item'>
                    {{ month }}
                </li>
                </ul>
              </div>
            </div>
              <div class="font-weight-bold">Selected Network</div>
              <!-- <v-chip label small class="mr-1 mb-1 pl-0" outlined :key="k" v-for="(v, k) in viewdata.data[0].network_detail">
                <v-chip label small class="mr-2">{{ v.name }}</v-chip>
                {{ v.name }}
              </v-chip> -->
              <v-chip label small class="mr-2">{{ viewdata.network_detail?.name || ""}}</v-chip>
            </div>
            <div class="mt-2">

              <lb-datatablenew :headers="gldetailTableHeaders" :items="viewdata.gldetail" :loading='viewloading'>
              </lb-datatablenew>
            </div>
          </div>
        </div>
        <div v-else-if="viewloading" class="grey--text py-4 text-center body-1">Loading Data...</div>
        <div v-else class="grey--text py-4 text-center body-1">No data found</div>
      </template>
      <template v-slot:actions>
        <div v-if="(viewdata.type === 'filematerialitygllevel' || viewdata.type === 'filematerialityrulevel') && (Object.keys(gldata).length > 25)" class="grey--text py-4 text-center body-1"> 
          <v-btn small @click="materialityDetailsPage--" :disabled="materialityDetailsPage === 0"><v-icon>mdi mdi-arrow-left</v-icon></v-btn>
          <span class="ml-1 mr-1" v-if="(($nova.formatNumber((Object.keys(gldata).length)/25))-materialityDetailsPage-1) !== 0">{{ materialityDetailsPage+1}} of {{ $nova.formatNumber((Object.keys(gldata).length)/25) }}</span>
          <span class="ml-1 mr-1" v-if="(($nova.formatNumber((Object.keys(gldata).length)/25))-materialityDetailsPage-1) === 0">End</span>
          <v-btn small @click="materialityDetailsPage++" :disabled="(((Object.keys(gldata).length)/25)-materialityDetailsPage-1) <= 0"><v-icon>mdi mdi-arrow-right </v-icon></v-btn>
        </div>
        <v-spacer></v-spacer>
        <v-btn small color="error" v-if="viewdata.stage === 0 && display !== 'view'" class="" @click="rejectItem(viewdata)">Reject</v-btn>
        <v-btn small color="success" @click="approveItem(viewdata)" v-if="viewdata.stage === 3 && display !== 'view'">Retry</v-btn>
        <v-btn small color="success" @click="approveItem(viewdata)" v-if="viewdata.stage === 0 && display !== 'view' ">Approve</v-btn>
      </template>
    </lb-dialog>
    <lb-dialog v-model="viewinfo" width="400" heading="Column Notations">
      <template v-slot:body>
        <v-card-text class="pa-4">
          <div class="my-1">
            <span color="info">LC Amt</span>
            <span class="ml-1">-</span>
            <span class="ml-2">Local Control Amount</span>
          </div>
          <v-divider></v-divider>
          <div class="my-1">
            <span color="info">LC %</span>
            <span class="ml-5">-</span>
            <span class="ml-2">Local Control Percentage</span>
          </div>
          <v-divider></v-divider>
          <div class="my-1">
            <span color="info">LM Amt</span>
            <span class="ml-1">-</span>
            <span class="ml-2">Local Management Amount</span>
          </div>
          <v-divider></v-divider>
          <div class="my-1">
            <span color="info">LM %</span>
            <span class="ml-4">-</span>
            <span class="ml-2">Local Management Percentage</span>
          </div>
          <v-divider></v-divider>
          <div class="my-1">
            <span color="info">RC Amt</span>
            <span class="ml-1">-</span>
            <span class="ml-2">Regional Control Amount</span>
          </div>
          <v-divider></v-divider>
          <div class="my-1">
            <span color="info">RC % </span>
            <span class="ml-4">-</span>
            <span class="ml-2">Regional Control Percentage</span>
          </div>
          <v-divider></v-divider>
          <div class="my-1">
            <span color="info">RM Amt </span>
            <span class="ml-1">-</span>
            <span class="ml-2">Regional Management Amount</span>
          </div>
          <v-divider></v-divider>
          <div class="my-1">
            <span color="info">RM %</span>
            <span class="ml-4">-</span>
            <span class="ml-2">Regional Management Percentage</span>
          </div>
          <v-divider></v-divider>
        </v-card-text>
      </template>
    </lb-dialog>

  </div>
</template>
<script>
// import Approvals from './Approvals.vue';

let styledxlsx = require('sheetjs-style');
export default {
  name: 'approval_list',
  data: function(){
    return {
      loading:false,
      viewloading: false,
      viewdialog: false,
      viewdata: {},
      gldata: {},
      type: "",
      requestdata: {},
      colors: {},
      formatteddata: [],
      selecteditem: "",
      typekeys: {"actual": "Actual", "forecast": "Forecast"},
      headers: [
        {
          value: "indexno",
          text: "S.No",
          sortable: false,
          filterable: false,
          alignment: "text-center"
        },
        {
          value: "typetext",
          text: "Request Type",
          sortable: false,
          filterable: false,
          datatype: "string",
          lock: true,
          visible: false,
        },
        {
          type: "string",
          value: "submittername",
          text: "Submitted By",
          sortable: false,
          filterable: false,
          // lock: true,
        },
        {
          type: "date",
          value: "submitdate",
          text: "Submitted On",
          sortable: true,
          defaultsort: 'desc'
          // lock: true,
        },
        {
          type: "string",
          value: "approvedby",
          text: "Resolved By",
          sortable: false,
          filterable: false,
        },
        {
          type: "string",
          value: "approvedon",
          text: "Resolved On",
          sortable: true,
        },
        {
          type: "string",
          value: "stage",
          text: "Status",
          sortable: false,
          filterable: false,
          // lock: true,
        },
        {
          value: "action",
          text: "Action",
          sortable: false,
          // lock: true,
        }
      ],
      glheaders: [
        {
          value: "period",
          text: "Period",
          alignment: "text-center",
          sortable: false,
        },
        {
          value: "code",
          text: "GL Code",
          sortable: true,
          filterable: true,
        },
        {
          value: "name",
          text: "Name",
          sortable: false,
          filterable: true,
        },
        {
          value: "datatypes",
          text: "",
          sortable: false,
          filterable: false,
          lock: true,
        },
        {
          value: "control_thresholds_amount",
          text: "LC Amt",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "control_thresholds_percent",
          text: "LC %",
          alignment: "text-right",
          sortable: false,
        },
        // {
        //   value: "floor_control_thresholds_amount",
        //   text: "L Ctrl FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        // },
        {
          value: "management_thresholds_amount",
          text: "LM Amt",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "management_thresholds_percent",
          text: "LM %",
          alignment: "text-right",
          sortable: false,
        },
        // {
        //   value: "floor_management_thresholds_amount",
        //   text: "L Mngt FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        // },
        {
          value: "control_thresholds_amount_regional",
          text: "RC Amt",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "control_thresholds_percent_regional",
          text: "RC %",
          alignment: "text-right",
          sortable: false,
        },
        // {
        //   value: "floor_control_thresholds_amount_regional",
        //   text: "R Ctrl FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        // },
        {
          value: "management_thresholds_amount_regional",
          text: "RM Amt",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "management_thresholds_percent_regional",
          text: "RM %",
          alignment: "text-right",
          sortable: false,
        },
        // {
        //   value: "floor_management_thresholds_amount_regional",
        //   text: "R Mngt FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        // },
      ],
      ruheaders: [
        {
          value: "period",
          text: "Period",
          alignment: "text-center",
          sortable: false,
        },
        {
          value: "datatypes",
          text: "",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "control_thresholds_amount",
          text: "LC Amt",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "control_thresholds_percent",
          text: "LC %",
          alignment: "text-right",
          sortable: false,
        },
        // {
        //   value: "floor_control_thresholds_amount",
        //   text: "L Ctrl FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        // },
        {
          value: "management_thresholds_amount",
          text: "LM Amt",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "management_thresholds_percent",
          text: "LM %",
          alignment: "text-right",
          sortable: false,
        },
        // {
        //   value: "floor_management_thresholds_amount",
        //   text: "L Mngt FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        // },
        {
          value: "control_thresholds_amount_regional",
          text: "RC Amt",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "control_thresholds_percent_regional",
          text: "RC %",
          alignment: "text-right",
          sortable: false,
        },
        // {
        //   value: "floor_control_thresholds_amount_regional",
        //   text: "R Ctrl FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        // },
        {
          value: "management_thresholds_amount_regional",
          text: "RM Amt",
          alignment: "text-right",
          sortable: false,
        },
        {
          value: "management_thresholds_percent_regional",
          text: "RM %",
          alignment: "text-right",
          sortable: false,
        },
        // {
        //   value: "floor_management_thresholds_amount_regional",
        //   text: "R Mngt FAmt",
        //   alignment: "text-right",
        //   sortable: false,
        // },
      ],
      dataStatusCount:{"open":-1,'approved':-1,'rejected':-1,'inProgress':-1,'retry':-1},
      gldetailTableHeaders : [
        {
          value: "indexno",
          text: "S.No",
          sortable: false,
          filterable: false,
          alignment: "text-center"
        },
        {
          text:'GLCode',
          value:'code',
          sortable: true,
          filterable: true,
        },
        {
          text:"Name",
          value:'name',
          filterable: true,
        }
      ],
      reportingunit:'',
      total_cycle:'',
      proccesed_cycle:'',
      viewinfo:false,
      page:0,
      filterOptionsData: this.filterData || [],
      previousFilter: {},
      filterLoading: true,
      meta:this.metaData || {},
      isFileGenarating : false,
      materialityDetailsPage:0,
    }
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    metaData:{
      type:Object,
      default: () => {}
    },
    display: {
      type: String,
      default: "view",
    },
    customHeaders: {
      type: Array,
      default: ()=> [],
    },
    pendingCountDisplay: {
      type: Boolean,
      default: true
    },
    approvedCountDisplay: {
      type: Boolean,
      default: true
    },
    rejectedCountDisplay: {
      type: Boolean,
      default: true
    },
    filterData:{
      type:Object,
      default:()=>[]
    }
  },
  activated() {
    this.getMetaData();
    this.refreshData();
  },
  mounted() {
    this.getMetaData();
    this.refreshData();
  },
  methods: {
    async refreshData() {
      if(this.display !== "view") this.headers.push();
      this.filterOptionsData = Array.from(this.filterOptionsData);
      this.filterOptionsData =[
        {
          value: 'name',
          key: 'possibletypes',
          name: "Request Type",
          data: (this.filterData?.type || []).map((id) => ({
                    _id: id.type || "",
                    name: id.code
                }))
        },
        {
          value: 'displayname',
          key: 'submitter',
          data: (this.filterData?.submitter_detail || []),
          name: "Submitted By"
        },
        {
          value: 'submittedon',
          key: 'submitdate',
          name: "Submitted On",
          type: 'daterange',
          data: []
        },
        {
          value: 'displayname',
          key: 'approver',
          data:  (this.filterData?.approver_detail || []),
          name: "Resolved By"
        },
        {
          value: 'resolvedon',
          key: 'approvedate',
          name: "Resolved On",
          type: 'daterange',
          data: []
        },
        {
          value: 'status',
          key: 'stage',
          name: "Status",
          data: [{ status: "Open", _id: 0 }, { status: "Approved", _id: 1 }, { status: "Rejected", _id: -1 }, { status: "Processing", _id: 2 }]
        },
      ],
      this.filterOptionsData = Array.from(this.filterOptionsData);
      this.filterLoading =false
      this.formatteddata = this.formatData(this.data);
      // this.getData();
      if(this.customHeaders.length>0){
        this.headers = this.customHeaders;
      }
    },
    getDetails(item) {
      this.viewloading = true;
      this.viewdialog = true;
      this.materialityDetailsPage = 0;
      this.viewdata = {};
      this.selecteditem = item;
      let url = "/v2/varianceanalysis/approvals/getdetail/"
      if(item.type === 'glselectionchange'){
        url = "/v2/varianceanalysis/approvals/glselectiongetdetail/"
      }
      this.axios.post(url+item._id).then(dt => {
        if(dt.data.status === "success") {
          this.viewdata = dt.data.data || {};
          this.reportingunit= dt.data.data.reportingunit;
          this.total_cycle= dt.data?.data?.total_cycle || [];
          this.proccesed_cycle = dt.data?.data?.proccesed_cycles || [];
          if(this.viewdata.userlist instanceof Array){
            let t = {};
            for (const i of this.viewdata.userlist) {
              t[i._id] = i;
            }
            this.viewdata.__userlist = t;
          }
          if(this.viewdata.reportingunit_detail instanceof Array){
            let t = {};
            for (const i of this.viewdata.reportingunit_detail) {
              t[i._id] = i;
            }
            this.viewdata.__reportingunit_detail = t;
          }
          if(this.viewdata.gllist instanceof Array){
            let t = {};
            for (const i of this.viewdata.gllist) {
              t[i._id] = i;
            }
            this.viewdata.__gllist = t;
          }
          if(this.viewdata.type === "materialityrulevel" || this.viewdata.type === "bulkmaterialityrulevel"){
            this.gldata = {"ru": this.formatGLData(this.viewdata.data[0]?.materiality || {})}
            this.updateStageById(this.formatteddata, dt.data.data._id ,dt.data.data.stage)
          }
          else if(this.viewdata.type === "filematerialityrulevel"){
            this.gldata = {}
            for (const i of (this.viewdata.data[0]?.materiality || [])) {
              this.gldata[i.rucode] = this.gldata[i.rucode] || {};
              if(i.actual) {
                this.gldata[i.rucode]["actual"] = this.gldata[i.rucode]["actual"] || [];
                this.gldata[i.rucode]["actual"].push({...(i.actual || {}), glcode: i.glcode, period:i?.period || '-'})
              }
              if(i.forecast) {
                this.gldata[i.rucode]["forecast"] = this.gldata[i.rucode]["forecast"] || [];
                this.gldata[i.rucode]["forecast"].push({...(i.forecast || {}), glcode: i.glcode,period:i?.period || '-'})
              }
            }
            for (const k in this.gldata) {
              if (Object.hasOwnProperty.call(this.gldata, k)) {
                const el = this.gldata[k];
                if(el.actual) el.actual = this.formatGLData(el.actual);
                if(el.forecast) el.forecast = this.formatGLData(el.forecast);
              }
            }
            this.updateStageById(this.formatteddata, dt.data.data._id, dt.data.data.stage);
          }
          else if(this.viewdata.type === "materialitygllevel" || this.viewdata.type === "bulkmaterialitygllevel"){
            this.gldata = {"one": this.formatGLData(this.viewdata.data[0]?.materiality || [])}
            this.updateStageById(this.formatteddata, dt.data.data._id ,dt.data.data.stage)
          }
          else if(this.viewdata.type === "filematerialitygllevel"){
            this.gldata = {}
            console.log("Materiality Data ", this.viewdata.data[0]);
            for (const i of (this.viewdata.data[0]?.materiality || [])) {
              this.gldata[i.rucode] = this.gldata[i.rucode] || {};
              for (const j of i.data || []) {
                if (j.actual) {
                  this.gldata[i.rucode]["actual"] = this.gldata[i.rucode]["actual"] || [];
                  this.gldata[i.rucode]["actual"].push({ ...(j.actual || {}), glcode: j.glcode,period:j?.period || '-' })
                } else console.log("no actual found");
                if (j.forecast) {
                  this.gldata[i.rucode]["forecast"] = this.gldata[i.rucode]["forecast"] || [];
                  this.gldata[i.rucode]["forecast"].push({ ...(j.forecast || {}), glcode: j.glcode,period:j?.period || '-' })
                } else console.log("no forecast found");
              }
            }
            for (const k in this.gldata) {
              if (Object.hasOwnProperty.call(this.gldata, k)) {
                const el = this.gldata[k];
                if(el.actual) el.actual = this.formatGLData(el.actual);
                if(el.forecast) el.forecast = this.formatGLData(el.forecast);
              }
            }
            this.updateStageById(this.formatteddata, dt.data.data._id, dt.data.data.stage);
          }
          else if(this.viewdata.data[0].type === 'glselectionchange'){
            this.viewdata = this.viewdata.data[0] || []
            if(typeof this.viewdata.data[0]?.gls_actual !== 'undefined'){
              this.viewdata.data[0].cycle = 'Actual'
            }else{
              this.viewdata.data[0].cycle = 'Forecast'
            }
            console.log(this.viewdata)
          }
        }else throw new Error ("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.viewloading = false;
      })
    },
    formatGLData(dt){
      let ndt = [];
      for (const i of dt) {
        ndt.push({
            code: this.viewdata.__gllist?.[i.glcode]?.code ?? "",
            name: this.viewdata.__gllist?.[i.glcode]?.name ?? "",
            control_thresholds_amount_proforma: i?.control_thresholds_amount_proforma ?? i?.control_thresholds_amount ?? '',
            control_thresholds_percent_proforma: i?.control_thresholds_percent_proforma ?? i?.control_thresholds_percent ?? '',
            // floor_control_thresholds_amount_proforma: i?.floor_control_thresholds_amount_proforma || 0,
            management_thresholds_amount_proforma: i?.management_thresholds_amount_proforma ?? i?.management_thresholds_amount ?? '',
            management_thresholds_percent_proforma: i?.management_thresholds_percent_proforma ?? i?.management_thresholds_percent ?? '',
            // floor_management_thresholds_amount_proforma: i?.floor_management_thresholds_amount_proforma || 0,
            control_thresholds_amount_regional_proforma: i?.control_thresholds_amount_regional_proforma ?? i?.control_thresholds_amount_regional ?? '',
            control_thresholds_percent_regional_proforma: i?.control_thresholds_percent_regional_proforma ?? i?.control_thresholds_percent_regional ?? '',
            // floor_control_thresholds_amount_regional_proforma: i?.floor_control_thresholds_amount_regional_proforma || '',
            management_thresholds_amount_regional_proforma: i?.management_thresholds_amount_regional_proforma ?? i?.management_thresholds_amount_regional ?? '',
            management_thresholds_percent_regional_proforma: i?.management_thresholds_percent_regional_proforma ?? i?.management_thresholds_percent_regional ?? '',
            // floor_management_thresholds_amount_regional_proforma: i?.floor_management_thresholds_amount_regional_proforma || '',
            control_thresholds_amount_forecast: i?.control_thresholds_amount_forecast ?? i?.control_thresholds_amount ?? '',
            control_thresholds_percent_forecast: i?.control_thresholds_percent_forecast ?? i?.control_thresholds_percent ?? '',
            // floor_control_thresholds_amount_forecast: i?.floor_control_thresholds_amount_forecast || '',
            management_thresholds_amount_forecast: i?.management_thresholds_amount_forecast ?? i?.management_thresholds_amount ?? '',
            management_thresholds_percent_forecast: i?.management_thresholds_percent_forecast ?? i?.management_thresholds_percent ?? '',
            // floor_management_thresholds_amount_forecast: i?.floor_management_thresholds_amount_forecast || '',
            control_thresholds_amount_regional_forecast: i?.control_thresholds_amount_regional_forecast ?? i?.control_thresholds_amount_regional ?? '',
            control_thresholds_percent_regional_forecast: i?.control_thresholds_percent_regional_forecast ?? i?.control_thresholds_percent_regional ?? '',
            // floor_control_thresholds_amount_regional_forecast: i?.floor_control_thresholds_amount_regional_forecast || '',
            management_thresholds_amount_regional_forecast: i?.management_thresholds_amount_regional_forecast ?? i?.management_thresholds_amount_regional ?? '',
            management_thresholds_percent_regional_forecast: i?.management_thresholds_percent_regional_forecast ?? i?.management_thresholds_percent_regional ?? '',
            period: i.period ?? '-'
            // floor_management_thresholds_amount_regional_forecast: i?.floor_management_thresholds_amount_regional_forecast || '',
          }
        )
        console.log(ndt)
      }
      return ndt;
    },
    approveItem(item) {
      this.viewloading = true;
      // this.$emit("loading", this.viewloading);
      let url ='/v2/varianceanalysis/approvals/approve/'
      if (item.type === 'glselectionchange'){
        url = '/v2/varianceanalysis/approvals/approveforglselection/'
      }
      this.axios.post(url+item._id).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Change request approved and the changes will be impacted only if there is/are no conflicts");
          this.requestdata = dt.data.data[0] || {};
          if(this.viewdata.type =='materialityrulevel' || this.viewdata.type =='materialitygllevel' || this.viewdata.type =='bulkmaterialityrulevel' || this.viewdata.type =='filematerialityrulevel' || this.viewdata.type =='bulkmaterialitygllevel' || this.viewdata.type =='filematerialitygllevel')
          {
            this.updateStageById(this.formatteddata,item._id, 2);
          }
          else
          {
            // item.stage = 1;
            // this.selecteditem.stage = 'Approved';
            // this.dataStatusCount.approved++;
            // this.dataStatusCount.open--;
            this.updateStageById(this.formatteddata,item._id, 1);
          }
          this.viewdialog = false;
        }
        else throw dt.data.message;
      }).catch(err => {
        console.log("error at approveItem function",err)
        this.viewdata.stage = 3
        this.updateStageById(this.formatteddata,item._id, 3);
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.viewloading = false;
        // this.$emit("loading", this.viewloading);
      })
    },
    rejectItem(item) {
      this.viewloading = true;
      // this.$emit("loading", this.viewloading);
      let url = '/v2/varianceanalysis/approvals/reject/'
      if (item.type === 'glselectionchange') {
        url = '/v2/varianceanalysis/approvals/rejectforglselection/'
      }
      this.axios.post(url+item._id).then(dt => {
        if(dt.data.status === "success") {
          this.$store.commit("sbSuccess", "Change request rejected");
          this.requestdata = dt.data.data[0] || {};
          item.stage = -1;
          this.selecteditem.stage = 'Rejected';
          // this.dataStatusCount.rejected++;
          // this.dataStatusCount.open--;
          this.updateStageById(this.formatteddata,item._id, -1);
          this.viewdialog = false;
        }
        else throw dt.data.message;
      }).catch(err => {
        this.viewdata.stage = 3
        this.updateStageById(this.formatteddata,item._id, 3);
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.viewloading = false;
        // this.$emit("loading", this.viewloading);
      })
    },
    setColors(){
      this.colors = {};
      let colorlist = this.$nova.getColors(10, 0.2);
      this.colors["materialityrulevel"] = colorlist[0];
      this.colors["bulkmaterialityrulevel"] = colorlist[1];
      this.colors["materialitygllevel"] = colorlist[2];
      this.colors["bulkmaterialitygllevel"] = colorlist[3];
      this.colors["filematerialityrulevel"] = colorlist[4];
      this.colors["filematerialitygllevel"] = colorlist[5];
      this.colors["glorder"] = colorlist[6];
      this.colors["accesschange"] = colorlist[7];
      this.colors["bulkaccesschange"] = colorlist[8];
      this.colors["fileaccesschange"] = colorlist[9];
      this.colors["glselectionchange"] = colorlist[10];
    },
    formatData: function(data){
      let ArrayTemp = [];
      data.forEach((i, k) => {
        i.__key = k;
        i.indexno = k + 1;
        let stageName = "";
        // i.__click = this.getDetails;
        if (i.stage === 0 || i.stage === "Open") {
          stageName = "Open"
          // this.dataStatusCount.open++
        }
        else if(i.stage === 1 || i.stage === "Approved"){
          stageName ="Approved"
          // this.dataStatusCount.approved++
        }
        else if(i.stage === -1 || i.stage === "Rejected"){
          stageName = "Rejected"
          // this.dataStatusCount.rejected++
        }
        else if(i.stage === 2 || i.stage === "inProgress"){
          stageName = "inProgress"
          // this.dataStatusCount.open++
        }
        else if(i.stage === 3 || i.stage === "Retry"){
          stageName = "Retry"
          // this.dataStatusCount.retry++
        }
        i.stage = stageName;
        i.submittername= i.submitter_detail?.displayname || ""
        i.approvedby = i.approver_detail?.displayname || ""
        i.approvedon = i.approvedate || "";
        i.stageName = stageName;
        ArrayTemp.push(i);
      });
      this.setColors();
      return ArrayTemp;
    },
    updateStageById: function(data, id, newStage) {
      this.formatteddata = data.map(item =>
        item._id === id ? { ...item, stage: newStage} : item
      );
      this.formatData(this.formatteddata)
    },
    getData(d=this.page) {
      this.loading = true;
      this.previousFilter.page =  d;
      this.previousFilter.limit =  50;
      this.$emit('loading',true);
      let filter=JSON.parse(JSON.stringify(this.previousFilter));
      if(this.previousFilter?.submitdate?.end){
        if(filter["submitdate"].end !== undefined){
          let endDate = new Date(filter["submitdate"].end);

          endDate.setDate(endDate.getDate() + 1);

          let updatedEndDate = endDate.toISOString().split('T')[0];

          filter["submitdate"].end = updatedEndDate;
        }
      }
      if(this.previousFilter?.approvedate?.end){
        if(filter["approvedate"].end !== undefined){
          let endDate = new Date(filter["approvedate"].end);

          endDate.setDate(endDate.getDate() + 1);

          let updatedEndDate = endDate.toISOString().split('T')[0];

          filter["approvedate"].end = updatedEndDate;
        }
      }
      this.axios.post("/v2/varianceanalysis/approvals/getlist", { filter: filter}).then(dt => {
        if (dt.data.status === "success") {
          this.formatteddata = [...this.formatteddata, ...this.formatData(dt.data.data)];
          
        } else throw new Error("No requests found");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
      }).finally(() => {
        this.loading = false;
        this.$emit('loading', false);
      })
    },

    getRefreshData(d){
      if(d.reload === true){
        this.formatteddata = [];
        this.getMetaData();
        this.getData(0);

      }
    },

    loadNextPage(d) {
      this.page = d?.page || 0
      this.getData(this.page - 1);
    },
    getFilterData(filter={},d=this.page) {
      let formatFilter = this.perivousFilter || {}
      Object.keys(filter?.filter || {}).forEach(key => {
        if(['limit','page'].indexOf(key) === -1){
          let dataKey = this.filterOptionsData.find((item)=> item.key === key);
          if(dataKey.type === "daterange"){
            formatFilter[key] ={};
          }
          else{
            formatFilter[key] =[];
          }
          (filter?.filter?.[key] || []).forEach((item) => {
            
            if(dataKey.type === "daterange"){
              formatFilter[key]= item._id;
            }
            else{
              formatFilter[key].push(item._id);
            }
          });
          if(dataKey.type !== "daterange" && formatFilter[key].length ===0){
            delete formatFilter[key];
          }
        }
      })
      this.page = filter?.page || d || 0; 
      formatFilter.page =  this.page;
      formatFilter.limit= 50
      this.previousFilter = formatFilter || [];
      this.formatteddata = [];
      this.getData(this.page -1 );
    },
    generateFile(filter) {
      this.isFileGenarating = true
      let formatFilter = {type:[],approver:[],submitter:[],stage:[],"submitdate": [], "approvedate": []}

      Object.keys(filter?.filter || {}).forEach(key => {
        if(['limit','page'].indexOf(key) === -1){
          let dataKey = this.filterOptionsData.find((item)=> item.key === key);
          if(dataKey.type === "daterange"){
            formatFilter[key] ={};
          }
          else{
            formatFilter[key] =[];
          }
          (filter?.filter?.[key] || []).forEach((item) => {
            if(dataKey.type === "daterange"){
              formatFilter[key]= item._id;
            }
            else{
              formatFilter[key].push(item._id);
            }
          });
        }
      })
      // formatFilter.limit = 1000;
      // formatFilter.page = 0;
      this.axios.post("/v2/varianceanalysis/approvals/getlist", { filter: formatFilter}).then(dt => {
        if (dt.data.status === "success") {
          this.downloadExcel(dt.data?.data || [])
        } else throw new Error("Error reading data");
      }).catch(err => {
        this.$store.commit("sbError", err.message || err || "Unknown error!");
        console.log(err);
        this.isFileGenarating = false
      }).finally(() => {
        // this.loading = false;
        // this.$emit('loading', false);
        
      })
    },
    downloadExcel(data) {
      // Acquire Data (reference to the HTML table)
        let workbook = styledxlsx.utils.book_new();
        let primarycolor = "d8e8f0";
        // let heading2 = (this.tablecolors.heading.a || "").toString().replaceAll("#", "").toUpperCase();
        let body1 = "E3FFE3";
        // let body2 = "E8EEFF";
        let scolor = "84a3e1";
        // let ecolor = "d11b1b";
        let typecolors = {}
        typecolors["materialityrulevel"] = "e6ffcc";
        typecolors["bulkmaterialityrulevel"] = "e6ccff";
        typecolors["materialitygllevel"] = "b3e6ff";
        typecolors["bulkmaterialitygllevel"] = "ffccdc";
        typecolors["filematerialityrulevel"] = "ffd699";
        typecolors["filematerialitygllevel"] = "99ebff";
        typecolors["glorder"] = "df9fbf";
        typecolors["accesschange"] = "ecd8c6";
        typecolors["bulkaccesschange"] = "ffb3ec";
        typecolors["fileaccesschange"] = "f06197";
        typecolors["glselectionchange"] = "df9fbf";
        let filename = "Approvals";
        // console.log(this.reportingunitlist[this.selectedreportingunit]);
        // console.log(primarycolor, this.tablecolors, heading1, heading2, heading3, body1, body2, body3);
        let dt = [
          [
            ""
          ],
          [
            {v: "Tool admin approver - Report", s: {font: {bold: true}, fill: {fgColor: {rgb: scolor}}}}
          ],
          [

          ],
          [
            {v: "Request Type", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
            {v: "Submitter", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
            {v: "Submitted On", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
            {v: "Resolved By", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
            {v: "Resolved On", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
            {v: "Status", s: {font: {bold: true}, fill: {fgColor: {rgb: primarycolor}}}},
          ]];
        for (const v of data) {
          let statusName = '';
          let statusColor = '80ffaa'
          if(v.stage === 0) {
            statusName = "Open";
            statusColor = '00ffff'
          }
          else if(v.stage === 1) {
            statusName = "Approved";
            statusColor = '80ffaa'
          }
          else if(v.stage === 2) {
            statusName = "Processing";
            statusColor='ffcc00'
          } 
          else if(v.stage === -1) {
            statusName = "Rejected";
            statusColor = 'ff3300'
          }
          dt.push([
            {v: v?.typetext || "", s: {fill: {fgColor: {rgb: typecolors[v?.type]}}}},
            {v: v?.submitter_detail?.displayname || "", s: {fill: {fgColor: {rgb: body1}}}},
            {v: this.$nova.formatDateTime(v?.submitdate), s: {fill: {fgColor: {rgb: body1}}}},
            {v: v?.approver_detail?.displayname || "", s: {fill: {fgColor: {rgb: body1}}}},
            {v: this.$nova.formatDateTime(v?.approvedate) || "", s: {fill: {fgColor: {rgb: body1}}}},
            {v: statusName || "", s: {fill: {fgColor: {rgb: statusColor}}}},
            
          ])
        }

        let worksheet = styledxlsx.utils.aoa_to_sheet(dt);

        styledxlsx.utils.book_append_sheet(workbook, worksheet, "TOP SHEET");
  
        styledxlsx.writeFile(workbook, `${filename}.xlsx`);
        this.isFileGenarating = false
    },
    getMetaData(){
      this.dataStatusCount={"open":-1,'approved':-1,'rejected':-1,'inProgress':-1,'retry':-1};
      this.axios.post('/v2/varianceanalysis/approvals/getApprovalCounts').then(dt=>{
        if(dt.data.status === 'success'){
          this.meta = dt.data.meta;
          this.dataStatusCount.open = this.meta?.pending || 0;
          this.dataStatusCount.approved = this.meta?.approved || 0;
          this.dataStatusCount.rejected = this.meta?.rejected || 0;
        }else{
          throw new Error(dt.data.message || 'Unknown error')
        }
      })
      .catch((err)=>{
        console.log("/v2/varianceanalysis/approvals/getApprovalCounts is throwing"+err);
        this.dataStatusCount={"open":0,'approved':0,'rejected':0,'inProgress':0,'retry':0},
        this.$store.commit("sbError", err.message || err || "Unknown error!");
      })
      .finally();
    }
  },
  computed: {
  },

}
</script>

<style scoped>
.bg-grey
{
  background-color: rgb(236 236 236);
}
.selected-months {
  margin-top: 0px;
}
.selected-months ul {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line */
  padding: 0;
  margin: 0;
  list-style-type: none; /* Remove default list styling */
}
.month-item {
  display: flex;
  align-items: center;
  margin: 2px 4px; /* Spacing around items */
  padding: 2px 4px;
  background-color: #ecf4f5; /* Background color for items */
  border-radius: 4px;
  font-size: 0.9em;
}
</style>